import Body from './Body.js';
import '../styles/App.css';
import {Routes, Route} from "react-router-dom"
import Bbe from "./bbe.js"

export default function App() {
  return (
    <div className="App">
      
      <Routes>
          <Route path='/*' element={<Body />} />
          <Route path='/bbe' element={<Bbe />} />
      </Routes>
    </div>
  );
}
