import logo from '../assets/images/logo512.png';
import '../styles/bbe.css';

function App() {
  return (
    <div className="bbe">
      <header className="bbe-header">
        <img src={logo} className="bbe-logo" alt="logo" />
        <p>
          Toupie Toupie, Toupie sacré
        </p>
        <a
          className="bbe-link"
          href="https://youtu.be/4vJC7h5vqu4?t=31"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pour les bandeur de toupie
        </a>
      </header>
    </div>
  );
}

export default App;
